import {
  faArrowRightFromBracket,
  faBookOpenReader,
  faCircleChevronRight,
  faClipboardQuestion,
  faClipboardUser,
  faFilePen,
  faShieldHalved,
  faSchool,
  faSignsPost,
  faUserGear,
  faUserPlus,
  faUsers,
  faStarHalfStroke,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlack, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useReducer } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  useAuthDispatch,
  useAuthState,
} from "../../../MainComponents/GlobalContext";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";
import logoDashLight from "../../../assets/logo-dash-light.png";
import logoDark from "../../../assets/logo-dark.png";
import { RiUserFollowLine } from "react-icons/ri";

function AdminLayout() {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();
  const navigate = useNavigate();

  const currentMode = localStorage.getItem("theme");

  const docElement = document.documentElement;
  if (currentMode === "dark") {
    docElement.classList.add("dark");
  }
  function handleToggleTheme(e) {
    if (e.target.checked) {
      docElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      docElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }

  const initialState = {
    collapse: false,
    showSidebar: false,
    refetch: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case "SetCollapse": {
        return {
          ...state,
          collapse: !state.collapse,
        };
      }
      case "SetRefetch": {
        return {
          ...state,
          refetch: !state.refetch,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  //!--------- get numbers in tabs-------
  const [NumbersInTabs, NumbersInTabsErrors] = useAxios(
    process.env.REACT_APP_ADMIN_NUMBERS_IN_TABS_API,
    "GET",
    "GET",
    state.refetch,
    "",
    "",
    AuthState.refetchIfAcceptOrDeleteRequest
  );

  function handleSignOut() {
    dispatch({
      type: "SetShowSidebar",
      payload: false,
    });

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/admin/signin");
  }
  return (
    <section className="flex h-screen max-h-screen w-full overflow-hidden bg-light dark:bg-dark dark:text-light md:pt-32">
      <section className="header-mobile-only fixed inset-0 top-0 z-50  hidden h-32 w-full items-center justify-between bg-secondary px-16 text-light md:flex">
        <div className="logo h-fit">
          <img
            className="h-24 cursor-pointer transition duration-200 ease-in-out hover:scale-105"
            onClick={() => navigate("/admin/dashboard")}
            src="https://api.yallafrench.com/uploads/assets/logo-dark.png"
            alt="logoDark"
          />
        </div>
        <div className="burger-dash bg">
          <input
            onChange={() =>
              dispatch({
                type: "SetShowSidebar",
                payload: !state.showSidebar,
              })
            }
            checked={state.showSidebar}
            type="checkbox"
            id="checkbox"
          />
          <label htmlFor="checkbox" className="toggle">
            <div className="bar bar--top"></div>
            <div className="bar bar--middle"></div>
            <div className="bar bar--bottom"></div>
          </label>
        </div>
      </section>
      {/**
       * //!outlet
       *
       */}
      <section className="outlet  h-full w-full overflow-y-auto  ">
        <Outlet />
      </section>
      {/**
       * //!placeholder
       *
       */}
      <div
        className={`placeholder h-full ${
          state.collapse ? "w-32" : "w-1/5 min-w-[20%]"
        }  transition-all duration-200 ease-in-out md:w-0 md:min-w-[0%]`}
      ></div>
      {/**
       * //!dash menu fixed
       *
       */}
      <div
        className={`dash-menu-fixed  z-40 ${
          state.collapse ? "w-32" : "w-1/5 "
        } fixed right-0 top-0 
                    h-full w-1/5 transition-all duration-200 ease-in-out ${
                      state.showSidebar
                        ? "md:pointer-events-auto md:w-full md:translate-x-0 md:opacity-100"
                        : "md:pointer-events-none  md:translate-x-20 md:opacity-0"
                    } md:pt-32`}
      >
        <div className="collapse-btn-wrapper relative w-full md:hidden ">
          <FontAwesomeIcon
            onClick={() =>
              dispatch({
                type: "SetCollapse",
              })
            }
            className={` absolute left-0 top-20 z-50 -translate-x-1/2 cursor-pointer rounded-full bg-light text-5xl transition duration-200 ease-in-out hover:scale-105 ${
              state.collapse && "rotate-180"
            }`}
            icon={faCircleChevronRight}
          />
        </div>
        <div className="dash-menu-container  flex  h-full w-full   flex-col items-center justify-between gap-10 overflow-y-auto bg-white dark:bg-black py-10">
          <div className="logo h-fit md:hidden">
            <img
              className={` ${
                state.collapse ? "h-32" : "h-48"
              }  cursor-pointer transition duration-200 ease-in-out hover:scale-105`}
              onClick={() => navigate("/")}
              src="https://api.yallafrench.com/uploads/assets/logo-dash-light.png"
              alt="logo-dash-light"
            />
          </div>

          <ul className="dash-menu-items flex h-fit w-full  flex-col items-center gap-5 ">
            <li>
              <div className="mb-6">
                <label className="switch cursor-pointer ">
                  <span className="sun">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g>
                        <circle r="5" cy="12" cx="12"></circle>
                        <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                      </g>
                    </svg>
                  </span>
                  <span className="moon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
                    </svg>
                  </span>
                  <input
                    type="checkbox"
                    className="dark-mode-checkbox"
                    value="dark"
                    onChange={handleToggleTheme}
                    defaultChecked={currentMode === "dark"}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </li>
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.length > 0) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/dashboard"
                  className={`group flex  h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      المنشورات
                    </p>
                    <FontAwesomeIcon
                      className={`${state.collapse ? "ml-2" : "ml-5 "} w-8`}
                      icon={faSignsPost}
                    />
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("student")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/students"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الطلاب
                    </p>
                    <FontAwesomeIcon className="ml-5 w-8" icon={faSchool} />
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("code")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/codes"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الأكواد
                    </p>
                    <FontAwesomeIcon
                      className="ml-5 w-8"
                      icon={faShieldHalved}
                    />
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("code_month")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/codecourses"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block whitespace-nowrap"
                      }`}
                    >
                      الأكواد كورس شهري
                    </p>
                    <FontAwesomeIcon className="ml-5 w-8" icon={faSlack} />
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("code_tracker")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/codes-tracker"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      تتبع الأكواد
                    </p>
                    <RiUserFollowLine className="ml-5 w-8" />
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("attendance")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/attendance"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الحضور
                    </p>

                    <FontAwesomeIcon
                      className="ml-5 w-8"
                      icon={faClipboardUser}
                    />
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("lecture")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/lectures"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      المحاضرات
                    </p>
                    <FontAwesomeIcon
                      className="ml-5 w-8"
                      icon={faBookOpenReader}
                    />
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("exams")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/exams"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الإختبارات
                    </p>
                    <FontAwesomeIcon className="ml-5 w-8" icon={faFilePen} />
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("questionbank")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/question-bank"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      بنك الأسئلة{" "}
                    </p>
                    <FontAwesomeIcon
                      className="ml-5 w-8"
                      icon={faClipboardQuestion}
                    />
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("group")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/groups"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      المجموعات
                    </p>
                    <FontAwesomeIcon className="ml-5 w-8" icon={faUsers} />
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("request")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/requests"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  {NumbersInTabs?.data?.requests_count &&
                    (AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) =>
                          permission.name.startsWith("request_accept")
                      )) && (
                      <span
                        className={`numbers flex items-center  justify-center rounded-full p-1  ${
                          state.collapse
                            ? "mb-5 h-3   w-3  bg-secondary text-[0px]"
                            : "h-10  w-10 min-w-[25px] min-h-[25px]  text-2xl"
                        }  text-secondary transition-all duration-200 ease-in-out group-hover:bg-light amd:mb-5 amd:h-3 amd:w-3  amd:min-w-[8px] amd:min-h-[8px] amd:bg-secondary amd:text-[0px] md:mb-0 md:h-10 md:w-10 md:min-w-[25px] md:min-h-[25px] md:bg-transparent md:text-2xl`}
                      >
                        {NumbersInTabs?.data?.requests_count}
                      </span>
                    )}

                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الطلبات
                    </p>
                    <FontAwesomeIcon className="ml-5 w-8" icon={faUserPlus} />
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("user")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/assistants"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      المساعدين
                    </p>
                    <FontAwesomeIcon className="ml-5 w-8" icon={faUserGear} />
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("whatsapp_show")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/whatsapp"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      واتساب
                    </p>
                    <FontAwesomeIcon
                      className="ml-5 w-8 text-3xl font-bold"
                      icon={faWhatsapp}
                    />
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("reviews")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/reviews"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center justify-end">
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      صور الطلاب
                    </p>
                    <FontAwesomeIcon
                      className="ml-5 w-8 text-3xl font-bold"
                      icon={faStarHalfStroke}
                    />
                  </div>
                </NavLink>
              </li>
            )}
            <li
              onClick={() =>
                dispatch({
                  type: "SetRefetch",
                })
              }
              className="flex h-full w-full justify-center"
            >
              <NavLink
                onClick={() =>
                  dispatch({
                    type: "SetShowSidebar",
                    payload: false,
                  })
                }
                to="/admin/profile"
                className={`group flex h-16 w-9/12  alg:w-11/12 ${
                  state.collapse
                    ? "items-center justify-center px-0"
                    : "items-center justify-between px-5"
                }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
              >
                <div className="text-icon  flex w-full items-center justify-end">
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    }`}
                  >
                    الملف الشخصي
                  </p>
                  <FontAwesomeIcon className="ml-5 w-8" icon={faUser} />
                </div>
              </NavLink>
            </li>
          </ul>

          <li className="log-out flex h-fit  w-full justify-center">
            <button
              onClick={() => handleSignOut()}
              className={`group flex h-16 w-9/12  alg:w-11/12 ${
                state.collapse
                  ? "items-center justify-center px-0"
                  : "items-center justify-end pr-5"
              }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
            >
              <div className="text-icon ">
                <p
                  className={` ${state.collapse ? "hidden" : " inline-block"} `}
                >
                  تسجيل الخروج
                </p>
                <FontAwesomeIcon
                  className="ml-5 w-8 rotate-180"
                  icon={faArrowRightFromBracket}
                />
              </div>
            </button>
          </li>
        </div>
      </div>
    </section>
  );
}

export default AdminLayout;
